import React, { Fragment, useEffect, useState } from 'react'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'

const AuthSessionCheck = function (props) {

    const { children }  = props
    const { isLoggedIn } = useAuth()
    let component
    const login_url = CmsConfig.LOGIN_URL
    const AUTHENTICATED = 'authenticated'
    const NOT_AUTHENTICATED = 'not authenticated'

    const [authenticated, setAuthenticated] = useState('pending')

    useEffect(() => {
        // Logger.info('isLoggedIn', isLoggedIn)
        if (isLoggedIn) {
            setAuthenticated(AUTHENTICATED)
        } else {
            setAuthenticated(NOT_AUTHENTICATED)
        }
    }, [isLoggedIn])

    if (authenticated === AUTHENTICATED) {
        component = children

    } else if (authenticated === NOT_AUTHENTICATED) {
        Logger.info('[AuthSessionCheck] redirect to login')
        window.location.replace(login_url)

    } else {
        component = <Fragment />
    }

    return <Fragment>{component}</Fragment>
}

export { AuthSessionCheck }
