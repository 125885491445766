import { REGISTER, LOGIN, LOGOUT, LIVE_INITIALIZED } from './actions'

export const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    token: null
}

const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case LIVE_INITIALIZED: {
            // const { user, token } = action.payload;
            debugger
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: true,
                token: 'Anonymous'
            }
        }

        case REGISTER: {
            const { user, token } = action.payload;
            return {
                ...state,
                user,
                token,
            }
        }
        case LOGIN: {
            const { user, token } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                token,
            }
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                token: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

export { AuthReducer }
