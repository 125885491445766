import { Button } from '@mui/material'
import { ReactComponent as PublishedIcon } from 'cms/assets/icons/circle-check-regular-published.svg'
// import { LoaderWrapper } from 'cms/auth/auth/components/loader-wrapper'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'
import { useCookies } from 'react-cookie'

const StageBanner = (props) => {

    const { children } = props
    const admin_auth_cookie_name = 'simplicity_admin'
    const environment_name = `${CmsConfig.Environment.Name}`
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies(admin_auth_cookie_name)
    const logout = () => {
        Logger.info('logout taking place')
        removeCookie('simplicity_admin', {path: '/', domain: CmsConfig.COOKIE_DOMAIN})
        removeCookie('simplicity_id', { path: '/', domain: CmsConfig.COOKIE_DOMAIN })
        removeCookie('simplicity_refresh', {path: '/', domain: CmsConfig.COOKIE_DOMAIN})
        // setSession()
        // const url = `${CmsConfig.API_BASEURL}${CmsConfig.LOGOUT_URL}`
        const url = `${CmsConfig.LOGOUT_URL}`
        Logger.info(`Logout: ${url}`)
        window.location.replace(url)

    }
    const onLogout = (event) => {
        event.preventDefault()
        logout()
    }

    return (
        <>
            <div style={{
                height: '40px', marginTop: '0', marginBottom: '0', padding: '16px',
                backgroundColor: '#2e2e2e', color: '#AAAAAA',
                display: 'flex', flexDirection: 'row',
                justifyContent: 'space-between', alignItems: 'center', gap: '16px',
            }}>
                <span style={{
                    height: '40px', display: 'flex', gap: '12px',
                    justifyContent: 'space-between', alignItems: 'center',
                }}>
                    <PublishedIcon style={{ fill: '#AAAAAA' }} /> Stage
                </span>
                <span>
                    You are using SimpliCity CMS [{environment_name}]
                </span>
                <span>
                    <Button
                        variant={'text'}
                        size={'small'}
                        style={{color: '#AAAAAA', textDecoration: 'underline'}}
                        onClick={onLogout}
                    >
                        Logout.
                    </Button>
                </span>
            </div>
            {children}
        </>
    )
}

export { StageBanner }
