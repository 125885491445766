import { validate as validateUUID, v4 as uuidv4 } from "uuid";
import { Logger } from "cms/utils/logger";

/**
 * Checks if a variable is defined. Returns true if the variable exists.
 * @param str A variable expected to exist
 * @returns {boolean}
 */
export const exists = function (str) {
    return !!str;
};
/**
 * Checks if a string is empty. Returns true if the string has no length.
 * @param str A variable expected to exist
 * @returns {boolean}
 */
export const isEmpty = function (str) {
    return str.length === 0;
};
/**
 * Checks if a string or array is not empty. Returns true if the string or array has length.
 * @param arr A variable expected to not be empty
 * @returns {boolean}
 */
export const isNotEmpty = function (arr) {
    return arr.length > 0;
};
/**
 * Checks if a variable is a string. Returns true if the variable is a string.
 * @param {unknown} str A variable expected to contain a string
 * @returns {str is string}
 */
export const isString = function (str) {
    return typeof str === "string";
};
/**
 * Checks if a variable is null. Returns true if the variable is null.
 * @param {unknown} str A variable expected to contain null
 * @returns {str is string}
 */
export const isNull = function (str) {
    return str === null;
};
export const isNotNull = function (str) {
    return str !== null;
};
/**
 * Checks if a string is a numeric value.
 * @param str
 * @returns {boolean}
 */
const isNumericString = (str) => {
    if (typeof str != "string") {
        return false;
    }
    return !isNaN(str) && !isNaN(parseFloat(str));
}

/**
 * Checks if a variable is a number. Returns true if the variable is a string.
 * @param number A variable expected to contain a number
 * @returns {boolean}
 */
export const isNumber = function (number) {
    return typeof number === "number";
};
/**
 * Checks if a variable is a boolean. Returns true if the variable is a string.
 * @param str A variable expected to contain a number
 * @returns {boolean}
 */
export const isBoolean = function (str) {
    return typeof str === "boolean";
};
/**
 * Checks if a variable is a Truthy. Returns true if the variable is a string.
 * @param str A variable expected to contain a number
 * @returns {boolean}
 */
export const isTrue = function (str) {
    if (isObject(str) || isArray(str)) {
        return false;
    }
    if (isNumber(str)) {
        if (str === 0) {
            return false;
        } else if (str === 1) {
            return true;
        } else {
            return !!str;
        }
    }
    if (isNumericString(str)) {
        return str === "1";
    }
    if (isString(str)) {
        return str === "true";
    }
    if (isBoolean(str)) {
        return str;
    }
    return Boolean(str).valueOf();
};
export const isFalse = function (str) {
    return !isTrue(str);
};

export const isObject = function (obj) {
    return typeof obj === "object";
};
export const objectHasProperty = function (obj, prop) {
    if (isNull(obj)) {
        return false;
    }
    const wasObject = isObject(obj);
    if (!wasObject) {
        return false;
    }
    const hasProperty = obj.hasOwnProperty(prop);
    const didExist = exists(obj[prop]);
    return wasObject && hasProperty && didExist;
};
export const objectHasKeys = function (obj) {
    const itExists = exists(obj);
    if (!itExists) {
        return false;
    }
    const isObj = isObject(obj);
    const hasKeys = Object.keys(obj).length;
    return itExists && isObj && hasKeys;
};
/**
 * Checks if a object variable exists and contains a string with some length.
 * Returns true if the variable is an object and has a property identified by 'prop'.
 * That property must also not be an empty string.
 * @param obj
 * @param prop
 * @returns {*|boolean}
 */
export const objectHasPropertyString = function (obj, prop) {
    const hasProperty = objectHasProperty(obj, prop);
    if (!hasProperty) {
        return false;
    }
    const wasString = isStringNotEmpty(obj[prop]);
    return hasProperty && wasString;
};
export const objectHasPropertyBoolean = function (obj, prop) {
    const hasProperty = objectHasProperty(obj, prop);
    if (!hasProperty) {
        return false;
    }
    const wasBoolean = isBoolean(obj[prop]);
    return hasProperty && wasBoolean;
};
export const objectHasPropertyNumber = function (obj, prop) {
    const hasProperty = objectHasProperty(obj, prop);
    if (!hasProperty) {
        return false;
    }
    const wasNumber = isNumber(obj[prop]);
    return hasProperty && wasNumber;
};
export const isArray = function (obj) {
    const wasArray = Array.isArray(obj);
    return wasArray;
};
export const objectHasPropertyArray = function (obj, prop) {
    const hasProperty = objectHasProperty(obj, prop);
    if (!hasProperty) {
        return false;
    }
    const wasArray = isArray(obj[prop]);
    return hasProperty && wasArray;
};
export const isArrayNotEmpty = function (obj) {
    const wasArray = isArray(obj);
    return wasArray && isNotEmpty(obj);
};
export const isArrayGreaterThan = function (obj, num) {
    if (!isNumber(num)) {
        throw Error("Must supply a number for isArrayGreaterThan");
    }
    return isArrayNotEmpty(obj) && obj.length > Number(num).valueOf();
};
export const isArraySingleValue = function (obj) {
    return isArrayNotEmpty(obj) && obj.length === 1;
};
export const isArrayCountEqual = function (obj, num) {
    if (!isNumber(num)) {
        throw Error("Must supply a number for isArrayCountEqual");
    }
    return isArrayNotEmpty(obj) && obj.length === Number(num).valueOf();
};
export const isArrayEmpty = function (obj) {
    const wasArray = isArray(obj);
    return wasArray && isEmpty(obj);
};
/**
 * Checks if a variable exists and is a string. Returns true if the variable both exists and is a string
 * @param str
 * @returns {boolean}
 */
export const existsIsString = function (str) {
    return exists(str) && isString(str);
};

/**
 * Checks if a string is not empty. Returns true is the variable exists, is a string and has length.
 * Returns false for undefined or empty strings.
 * @param str
 * @returns {boolean}
 */
export const isStringNotEmpty = function (str) {
    return isString(str) && isNotEmpty(str);
};
/**
 * Checks if a string is empty. Returns true is the variable exists, is a string and has length.
 * Returns false for undefined or empty strings.
 * @param str
 * @returns {boolean}
 */
export const isStringEmpty = function (str) {
    return isString(str) && isEmpty(str);
};
/**
 * Checks if a string is empty. Returns true is the variable exists, is a string and has length.
 * Returns false for undefined or empty strings.
 * @param number
 * @returns {boolean}
 */
export const isNumberInteger = function (number) {
    return isNumber(number) && Number.isInteger(number);
};
/**
 * Checks if a string is a number. Returns true is the variable exists, and is a string and that string
 * will parse to a number. Returns false for undefined variables, variables that are not strings
 * or strings that are not numbers.
 * @param str
 * @returns {boolean}
 */
export const isStringANumber = function (str) {
    return exists(str) && isNumericString(str);
};

/**
 * Tests if a string is a valid GUID (Globally Unique Identifier), using
 * the uuid module's validateUUID() function (https://github.com/uuidjs/uuid).
 * @param str The string you what to test. Must be a non-empty String.
 * @returns {boolean}
 */
export const isValidGuid = (str) => {
    return isStringNotEmpty && validateUUID(str);
};

export const generateGuid = () => {
    return uuidv4();
};

/**
 * Checks if Rich Text is not empty. looks to see if the array of paragraphs is 0
 *
 * @param richText
 * @returns {boolean|boolean}
 */
export const isRichTextNotEmpty = (richText) => {
    if (isObject(richText)) {
        const { content } = richText;
        if (isArrayEmpty(content)) {
            return true;
        }
        const number = content?.length;
        if (number === 1) {
            const innerContent = content[0]?.content;
            if (isArrayNotEmpty(innerContent)) {
                return true;
            } else {
                Logger.debug("Richtext was empty");
                return false;
            }
        }
        return true;
    }
};
/**
 * Checks if Rich Text is empty. looks to see if the array of paragraphs is 0
 *
 * @param richText
 * @returns {boolean|boolean}
 */
export const isRichTextEmpty = (richText) => {
    const notEmpty = isRichTextNotEmpty(richText);
    return !notEmpty;
};

// /**
//  * Checks if Rich Text is not empty. looks to see if the array of paragraphs is 0
//  *
//  * @param richText
//  * @returns {boolean|boolean}
//  */
// export const isRichTextNotEmpty = (richText) => {
//     if (isObject(richText)) {
//         const {content} = richText
//         const number = content.length
//         if (number === 1) {
//             const innerContent = content[0]?.content
//             if (isArrayNotEmpty(innerContent)) {
//                 return true
//             } else {
//                 console.log('Richtext was empty')
//                 return false
//             }
//         }
//         return true
//     }
// }
