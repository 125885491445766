import { useContext } from 'react'
import { SimpliCityAuthContext } from 'cms/auth/auth/simplicity-auth-context'
// import { SimpliCityNoAuthContext } from 'cms/auth/noauth/simplicity-noauth-context'
// import { CmsConfig } from 'cms/config'

const useAuth = () => {

    const context = useContext(SimpliCityAuthContext)
    // const authContext = useContext(SimpliCityAuthContext)
    // const noauthContext = useContext(SimpliCityNoAuthContext)
    // const context = CmsConfig.isPreview ? authContext : noauthContext

    if (!context) {
        throw new Error('context must be used inside provider')
    }

    return context
}

export { useAuth }
