import { ErrorDisplay } from "cms/shared/error_display";
import { usePageNav } from "cms/shared/page-nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import { Menu } from 'cms/shared/page-nav/menu'

/**
 * Temporary event nav (which looks like page nav); it's full implementation is
 * tracked in https://radicalio.atlassian.net/browse/SC-990
 */
const EventNav = (props) => {
    const { webpage, selectedSection } = props;
    const { guid } = webpage || {};

    const {
        error,
        // sectionTree,
        openMenus,
    } = usePageNav(guid);

    // const { children, nav_title } = sectionTree || {}
    const level = 1;
    const sections = [
        { title: "News", path: "/news" },
        { title: "Events", path: "/events" },
        { title: "Advisories", path: "/advisories" },
        { title: "Alerts", path: "/alerts" },
    ];

    return (
        <div className="side-nav event">
            <ul className={`menu-level-${level} ${openMenus?.includes(parent) ? "open" : ""}`}>
                {sections.map((section) => {
                    const { title, path } = section;
                    const isSelected = title === selectedSection;
                    return (
                        <li className={`menu-item ${isSelected ? "selected-section" : ""}`} key={title}>
                            <div className="selected-icon">
                                <span hidden={!isSelected}>
                                    <FontAwesomeIcon icon={`fa-solid fa-arrow-right`} />
                                </span>
                            </div>
                            <div className="title">
                                <a className={isSelected ? "active" : ""} href={path}>
                                    {title}
                                </a>
                                <div className="expanded">
                                    {/*<button>*/}
                                    {/*    <span><i className="fa-regular fa-minus"></i></span>*/}
                                    {/*    <span hidden="true"><i className="fa-regular fa-plus"></i></span>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
            <ErrorDisplay error={error} />
        </div>
    );
};

export { EventNav };
