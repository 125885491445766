import { CmsConfig } from 'cms/config'

const StageLogin = () => {

    const login_url = CmsConfig.LOGIN_URL
    const environmentName = CmsConfig.Environment.Name
    const isStage = CmsConfig.isStage

    if (!isStage) {
        return null
    }

    return (
        <div style={{
            height: '80px', marginTop: '0', padding: '16px',
            backgroundColor: '#2e2e2e', color: '#AAAAAA',
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center',
        }}>

            <span>{environmentName}</span>
            <span>You must be authenticated to use this application.</span>
            <a
                style={{ color: '#AAAAAA' }}
                href={login_url}>
                Go to login
            </a>
        </div>
    )
}

export { StageLogin }
