/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material'
import { SimpliCityContextProvider } from 'cms/context'
import { SimpliCityAuthProvider } from 'cms/auth/auth/simplicity-auth-context'
import { UserWayPlugin } from 'cms/integration/userway/userway'
import { LoadWebsite } from 'cms/shared/load-website'
import { RouterProvider } from 'react-router-dom'
import { router } from 'router/router'
import { Config } from 'config'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'
import { theme } from 'theme'

import 'styles/app-styles'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender"

const SimpliCityApp = () => {

    const [error, setError] = useState()
    const [website, setWebsite] = useState()
    const [settings, setSettings] = useState()
    const recaptcha_site_key = Config.recaptcha_site_key || CmsConfig.recaptcha_site_key
    const isPreview = CmsConfig.isPreview
    const isStage = CmsConfig.isStage
    const isLive = !isPreview && !isStage
    // const isPrerendering = Config.isPrerendering()

    useEffect(() => {
        // console.log(error)
        if (error) {
            Logger.error(error)
        }
    }, [error])

    console.log(`[isPreview : ${isPreview}]`)
    console.log(`[isStage : ${isStage}]`)
    console.log(`[isLive : ${isLive}]`)
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptcha_site_key}>
            <ThemeProvider theme={theme}>
                <SimpliCityAuthProvider>
                    <LoadWebsite
                        setError={setError}
                        setWebsite={setWebsite}
                        setSettings={setSettings}
                    />
                    {/*  Show an empty skeleton while data is loading  */}
                    {website &&
                        <SimpliCityContextProvider
                            website={website}
                            settings={settings}
                        >
                            {/*   The Router will pass processing context to the   */}
                            {/*   route *element* that matches the request         */}
                            <RouterProvider router={router} />
                        </SimpliCityContextProvider>
                    }
                </SimpliCityAuthProvider>
                <SkipDuringPrerender><UserWayPlugin /></SkipDuringPrerender>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    )
}

SimpliCityApp.displayName = 'SimpliCityApp'
export { SimpliCityApp }

