import { ErrorDisplay } from "cms/shared/error_display";
import { BreadcrumbList } from "./breadcrumb-list";
import { useFetchBreadcrumbs } from "../../../mydnv/components/shared/breadcrumb/use-fetch-breadcrumbs";

const Breadcrumb = (props) => {
    const { webpage, model } = props;

    const { crumbs, error } = useFetchBreadcrumbs(webpage, model);

    if (error) {
        return <ErrorDisplay error={error} />;
    }

    return <BreadcrumbList crumbs={crumbs} />;
};

Breadcrumb.displayName = "Breadcrumb";
export { Breadcrumb };
