import { CmsConfig } from 'cms/config'
// import { Logger } from 'cms/utils/logger'

const StageGuard = (props) => {

    const { children } = props
    const isStage = CmsConfig.isStage
    const isPreview = CmsConfig.isPreview
    // const isLive = !isPreview && !isStage

    if (!isStage) {
        return null
    }
    if (isPreview) {
        return null
    }
    return (
        <>{children}</>
    )
}

export { StageGuard }
