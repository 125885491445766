import { useEffect, useState, useMemo } from "react"
import { SimpliCitySDK } from "cms/sdk"
import { Logger } from "cms/utils/logger"
import { useAuth } from "cms/auth/auth/hooks/useAuth"

/**
 * @param webpage
 * @param model
 */
const useSeo = (webpage, model) => {

    const { token } = useAuth()
    const [seo, setSeo] = useState(null)
    const { guid: webpage_guid } = webpage || {}
    const hasModel = !!model

    useEffect(() => {
        if (!webpage_guid) {
            return
        }

        const fetchSEO = async () => {
            try {
                const result = await SimpliCitySDK.seo.findOneByWebpage(token, webpage_guid)
                setSeo(result)
            } catch (error) {
                Logger.error("Failed to fetch SEO data:", error)
            }
        }

        fetchSEO()
    }, [webpage_guid, token])

    const getImageData = (image) => image || null

    const evaluateFlags = (bool, modelValue, seoValue) => {
        return bool ? seoValue : modelValue
    }

    const seo_result = useMemo(() => {
        if (!hasModel) {
            return { seo }
        }

        const url = new URL(webpage?.path, window.location).href

        return {
            url,
            title: evaluateFlags(seo?.use_content_title ?? true, model?.title, seo?.title),
            description: evaluateFlags(seo?.use_content_description ?? true, model?.description, seo?.description),
            image: evaluateFlags(
                seo?.use_content_image ?? true,
                getImageData(model?.banner_image),
                getImageData(seo?.image)
            ),
            og_type: seo?.og_type ?? "website",
            og_title: evaluateFlags(seo?.og_use_main_title ?? true, model?.title, seo?.og_title),
            og_description: evaluateFlags(
                seo?.og_use_main_description ?? true,
                model?.description,
                seo?.og_description
            ),
            og_image: evaluateFlags(
                seo?.og_use_main_image ?? true,
                getImageData(model?.banner_image),
                getImageData(seo?.image)
            ),
            tw_card_type: seo?.tw_card_type ?? "summary_large_image",
            tw_title: evaluateFlags(seo?.tw_use_main_title ?? true, model?.title, seo?.tw_title),
            tw_description: evaluateFlags(
                seo?.tw_use_main_description ?? true,
                model?.description,
                seo?.tw_description
            ),
            tw_image: evaluateFlags(
                seo?.tw_use_main_image ?? true,
                getImageData(model?.banner_image),
                getImageData(seo?.tw_image)
            ),
        }
    }, [hasModel, webpage?.path, seo, model?.title, model?.description, model?.banner_image])

    return { seo: seo_result }
}
export { useSeo }
