
const MobileBackDrop = (props) => {

    const { toggleMobile } = props;

    return (
        <div className={"mobile-backdrop"} onClick={toggleMobile}></div>
    )
}

export { MobileBackDrop }
